var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav-form'),_c('section',[_c('article',[_c('field',{staticStyle:{"top":"5px","left":"10px"},attrs:{"name":"delegacion_id","widget":"m2o","searchable":"","label":"Delegación","width":"240px","readonly":_vm.mode == 'edit'}}),_c('field',{staticStyle:{"top":"5px","right":"320px"},attrs:{"name":"ndoc","widget":"char","searchable":"","label":"Nº Depósito","width":"90px","inputalign":"center","fontweight":"bold"}}),_c('field',{staticStyle:{"top":"5px","right":"210px"},attrs:{"name":"fecha","type":"date","default":"today","label":"F. Recepción","width":"100px"}}),_c('field',{staticStyle:{"top":"5px","right":"110px"},attrs:{"name":"norden","widget":"char","searchable":"","label":"Nº Orden","width":"90px"}}),_c('field',{staticStyle:{"top":"5px","right":"10px"},attrs:{"name":"total","type":"number","readonly":"","label":"Total Deposito","width":"90px"}}),_c('field',{staticStyle:{"top":"40px","left":"10px"},attrs:{"name":"almacen_origen_id","widget":"m2o","searchable":"","label":"Almacén origen","width":"320px","readonly":!_vm.formData.delegacion_id || _vm.mode == 'edit',"filter":_vm.formData.delegacion_id
            ? ['delegacion_id', '=', _vm.formData.delegacion_id.codigo]
            : null}}),_c('field',{staticStyle:{"top":"40px","left":"340px"},attrs:{"name":"almacen_destino_id","widget":"m2o","searchable":"","label":"Almacén destino","width":"320px","readonly":!_vm.formData.delegacion_id || _vm.mode == 'edit',"filter":_vm.formData.delegacion_id
            ? ['delegacion_id', '=', _vm.formData.delegacion_id.codigo]
            : null}}),_c('r-tabs',{ref:"main-tabs",staticStyle:{"top":"80px","height":"255px"},attrs:{"wt":_vm.formData.wt,"buttons":['General', 'Líneas']},on:{"active":_vm.onActiveTab}},[_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"10px","left":"14px"},attrs:{"name":"observaciones","widget":"text","label":"Observaciones","width":"300px","height":"47px"}}),_c('field',{staticStyle:{"top":"82px","right":"450px"},attrs:{"name":"totalunidades","type":"number","readonly":"","inline-label":"","label":"Total unidades","width":"100px"}}),_c('field',{staticStyle:{"top":"107px","right":"450px"},attrs:{"name":"totalpeso","type":"number","readonly":"","inline-label":"","label":"Total peso","width":"100px"}}),_c('field',{staticStyle:{"top":"140px","left":"10px"},attrs:{"name":"albaranemitido","widget":"checkbox","inline-label":"","reverse":"","label":"Albarán emitido"}}),_c('field',{staticStyle:{"top":"165px","left":"10px"},attrs:{"name":"etiquetasemitidas","widget":"checkbox","inline-label":"","reverse":"","label":"Etiquetas emitidas"}}),_c('field',{ref:"estadisticas_familias",staticStyle:{"top":"5px","right":"10px","width":"320px"},attrs:{"name":"estadisticas_familias","widget":"handsontable","height":200,"minRows":8,"readonly":true,"columns":[
              {
                name: 'familia_id',
                header: 'Familia',
                type: 'm2o',
                label: 'nombre',
                primary: 'codigo',
                showCode: true,
                model: 'familia',
              },
              {
                name: 'unidades',
                header: 'Uds.',
                type: 'numeric',
                format: '0',
              },
              {
                name: 'precio_medio',
                header: 'Prec. medio',
                type: 'numeric',
                format: '0,0.00',
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{ref:"lineas",staticClass:"lineas",staticStyle:{"top":"5px","left":"4px","width":"648px"},attrs:{"name":"lineas","htSettings":{ rowHeights: 15 },"widget":"handsontable","height":203,"minRows":12,"customprop":"lineas_customprop","columns":[
              { name: 'ean13', header: '|||||||||' },
              {
                name: 'articulo_id',
                header: 'Artículo',
                help: 'Código del artículo',
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
                noOpen: true,
                fields: [
                  { name: 'thumbnail', fields: ['imagen'] },
                  { name: 'familia_id', fields: ['codigo', 'nombre'] },
                  'tarifa1',
                  'tarifa2',
                  'tarifa3',
                  'tarifa4',
                  'tarifa5',
                  'tarifa6',
                  'tarifa7',
                  'tarifa8',
                  'tarifa9',
                  'tarifa10' ],
              },
              { name: 'descripcion', header: 'Descripción' },
              {
                name: 'modelo_id',
                header: 'Modelo',
                help: 'Modelo del artículo',
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
                readOnly: true,
              },
              {
                name: 'refproveedor',
                header: 'R. Proveedor',
                help: 'Ref. del proveedor del artículo',
              },
              {
                name: 'unidades',
                header: 'Unid.',
                help: 'Unidades',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              { name: 'etiqueta', header: 'Etiq.', type: 'numeric' },
              {
                name: 'peso',
                header: 'Peso',
                help: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'v',
                header: 'V',
                help: '<U> Valorado por Unidad <P> Valorado por Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0' },
              },
              {
                name: 't',
                header: 'T',
                help: ' Indicar el nº de Tarifa del formulario de artículos (1-10)',
                type: 'numeric',
                numericFormat: { pattern: '0,0' },
              },
              {
                name: 'precio',
                header: 'Precio',
                help: 'Precio',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'totallinea',
                header: 'Total Línea',
                help: 'Total Linea',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              } ]}})],1)])],1)]),_c('v-collapse-wrapper',{ref:"main-collapse",attrs:{"active":true}},[_c('div',{directives:[{name:"collapse-toggle",rawName:"v-collapse-toggle"}],staticClass:"header"},[_vm._v(" Listado "),_c('div',{staticClass:"item-after"},[_vm._v(_vm._s(_vm.count))])]),_c('div',{directives:[{name:"collapse-content",rawName:"v-collapse-content"}],staticClass:"my-content"},[_c('hot-table',{ref:"hotTableComponent",attrs:{"settings":_vm.htSettings}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }