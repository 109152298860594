<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article>
        <field
          name="delegacion_id"
          widget="m2o"
          searchable
          label="Delegación"
          width="240px"
          style="top: 5px; left: 10px"
          :readonly="mode == 'edit'"
        />
        <field
          name="ndoc"
          widget="char"
          searchable
          label="Nº Depósito"
          width="90px"
          style="top: 5px; right: 320px"
          inputalign="center"
          fontweight="bold"
        />
        <field
          name="fecha"
          type="date"
          default="today"
          label="F. Recepción"
          width="100px"
          style="top: 5px; right: 210px"
        />
        <field
          name="norden"
          widget="char"
          searchable
          label="Nº Orden"
          width="90px"
          style="top: 5px; right: 110px"
        />
        <field
          name="total"
          type="number"
          readonly
          label="Total Deposito"
          width="90px"
          style="top: 5px; right: 10px"
        />
        <field
          name="almacen_origen_id"
          widget="m2o"
          searchable
          label="Almacén origen"
          width="320px"
          style="top: 40px; left: 10px"
          :readonly="!formData.delegacion_id || mode == 'edit'"
          :filter="
            formData.delegacion_id
              ? ['delegacion_id', '=', formData.delegacion_id.codigo]
              : null
          "
        />
        <field
          name="almacen_destino_id"
          widget="m2o"
          searchable
          label="Almacén destino"
          width="320px"
          style="top: 40px; left: 340px"
          :readonly="!formData.delegacion_id || mode == 'edit'"
          :filter="
            formData.delegacion_id
              ? ['delegacion_id', '=', formData.delegacion_id.codigo]
              : null
          "
        />

        <r-tabs
          ref="main-tabs"
          style="top: 80px; height: 255px"
          :wt="formData.wt"
          :buttons="['General', 'Líneas']"
          @active="onActiveTab"
        >
          <div class="tab">
            <field
              name="observaciones"
              widget="text"
              label="Observaciones"
              width="300px"
              height="47px"
              style="top: 10px; left: 14px"
            />
            <field
              name="totalunidades"
              type="number"
              readonly
              inline-label
              label="Total unidades"
              width="100px"
              style="top: 82px; right: 450px"
            />
            <field
              name="totalpeso"
              type="number"
              readonly
              inline-label
              label="Total peso"
              width="100px"
              style="top: 107px; right: 450px"
            />
            <field
              name="albaranemitido"
              widget="checkbox"
              inline-label
              reverse
              label="Albarán emitido"
              style="top: 140px; left: 10px"
            />
            <field
              name="etiquetasemitidas"
              widget="checkbox"
              inline-label
              reverse
              label="Etiquetas emitidas"
              style="top: 165px; left: 10px"
            />
            <field
              ref="estadisticas_familias"
              name="estadisticas_familias"
              widget="handsontable"
              :height="200"
              :minRows="8"
              style="top: 5px; right: 10px; width: 320px"
              :readonly="true"
              :columns="[
                {
                  name: 'familia_id',
                  header: 'Familia',
                  type: 'm2o',
                  label: 'nombre',
                  primary: 'codigo',
                  showCode: true,
                  model: 'familia',
                },
                {
                  name: 'unidades',
                  header: 'Uds.',
                  type: 'numeric',
                  format: '0',
                },
                {
                  name: 'precio_medio',
                  header: 'Prec. medio',
                  type: 'numeric',
                  format: '0,0.00',
                },
              ]"
            />
          </div>
          <div class="tab">
            <field
              ref="lineas"
              name="lineas"
              class="lineas"
              :htSettings="{ rowHeights: 15 }"
              widget="handsontable"
              :height="203"
              :minRows="12"
              style="top: 5px; left: 4px; width: 648px"
              customprop="lineas_customprop"
              :columns="[
                { name: 'ean13', header: '|||||||||' },
                {
                  name: 'articulo_id',
                  header: 'Artículo',
                  help: 'Código del artículo',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  noOpen: true,
                  fields: [
                    { name: 'thumbnail', fields: ['imagen'] },
                    { name: 'familia_id', fields: ['codigo', 'nombre'] },
                    'tarifa1',
                    'tarifa2',
                    'tarifa3',
                    'tarifa4',
                    'tarifa5',
                    'tarifa6',
                    'tarifa7',
                    'tarifa8',
                    'tarifa9',
                    'tarifa10',
                  ],
                },
                { name: 'descripcion', header: 'Descripción' },
                {
                  name: 'modelo_id',
                  header: 'Modelo',
                  help: 'Modelo del artículo',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  readOnly: true,
                },
                {
                  name: 'refproveedor',
                  header: 'R. Proveedor',
                  help: 'Ref. del proveedor del artículo',
                },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  help: 'Unidades',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                { name: 'etiqueta', header: 'Etiq.', type: 'numeric' },
                {
                  name: 'peso',
                  header: 'Peso',
                  help: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'v',
                  header: 'V',
                  help: '<U> Valorado por Unidad <P> Valorado por Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0' },
                },
                {
                  name: 't',
                  header: 'T',
                  help: ' Indicar el nº de Tarifa del formulario de artículos (1-10)',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0' },
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  help: 'Precio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'totallinea',
                  header: 'Total Línea',
                  help: 'Total Linea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
              ]"
            />
          </div>
        </r-tabs>
        <!-- tabs -->
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import CompraMixin from "./../components/CompraMixin.js";
import FacturaMixin from "./../components/FacturaMixin.vue";
var floatOrZero = function (val) {
  return isFinite(parseFloat(val || 0.0)) ? parseFloat(val || 0.0) : 0.0;
};
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Depósitos a representantes",
      dbAdapter: "deposito_representante",
      primary: "ndoc",
      defaultDataSearch: {
        wt: 0,
      },
      defaultData: {
        wt: 0,
        fecha: new Date().yyyymmdd(),
        delegacion_id: self.app.config.delegacion_defecto || null,
      },
      sequence: {
        name: function () {
          return "deposito_representante_" + (self.formData.wt ? "1" : "0");
          /*return self.formData.tipo_id
            ? "deposito_representante_" + (self.formData.wt ? "1" : "0")
            : null;*/
        },
        params: ["wt"],
      },
      fields: ["wt"],
    };
  },
  watch: {
    mode: function (newVal, oldVal) {
      var self = this;
      setTimeout(() => self.actualizar_estadisticas_familias(), 0);
    },
  },
  methods: {
    actualizar_total_unidades() {
      let self = this;
      let h = self.$refs.lineas.field.hotInstance;
      let t = h
        .getDataAtProp("unidades")
        .reduce(
          (accumulator, currentValue) =>
            accumulator + floatOrZero(currentValue),
          0
        );
      self.$set(self.formData, "totalunidades", t);
      self.$emit("change", "totalunidades", "auto");
    },
    actualizar_total_peso() {
      let self = this;
      let h = self.$refs.lineas.field.hotInstance;
      let t = h
        .getDataAtProp("peso")
        .reduce(
          (accumulator, currentValue) =>
            accumulator + floatOrZero(currentValue),
          0
        );
      self.$set(self.formData, "totalpeso", t.toFixed(3));
      self.$emit("change", "totalpeso", "auto");
    },
    actualizar_totallinea(row) {
      let self = this;
      let h = self.$refs.lineas.field.hotInstance;
      let val = (columna) => h.getDataAtRowProp(row, columna) || 0;
      h.getSourceData()[row].totallinea =
        (val("v") == "P"
          ? floatOrZero(val("peso"))
          : floatOrZero(val("unidades"))) * floatOrZero(val("precio"));
      self.actualizar_total();
      self.renderH(h);
    },
    actualizar_total() {
      let self = this;
      let h = self.$refs.lineas.field.hotInstance;
      let t = h
        .getSourceData()
        .reduce((t, l) => t + floatOrZero(l.totallinea), 0);
      self.$set(self.formData, "total", t.toFixed(2));
      self.$emit("change", "total", "auto");
    },
    actualizar_estadisticas_familias() {
      var self = this;
      if (!self.$refs.estadisticas_familias) return;
      let h = self.$refs.lineas.field.hotInstance;
      let hf = self.$refs.estadisticas_familias.field.hotInstance;
      hf.cachedItems = hf.cachedItems || {};
      hf.cachedItems.familia_id = hf.cachedItems.familia_id || {};
      var datos = {};
      var darr = [];

      h.getSourceData().forEach(function (line, i) {
        if (line["articulo_id"]) {
          if (typeof line["articulo_id"] == "object") {
            if (!line["articulo_id"]["familia_id"]) return;
            var id = line["articulo_id"]["familia_id"]["codigo"];
            hf.cachedItems.familia_id[id] = line["articulo_id"]["familia_id"];
          } else {
            if (
              !h.cachedItems["articulo_id"][line["articulo_id"]] ||
              !h.cachedItems["articulo_id"][line["articulo_id"]]["familia_id"]
            )
              return;
            id =
              h.cachedItems["articulo_id"][line["articulo_id"]]["familia_id"][
                "codigo"
              ];
            hf.cachedItems.familia_id[id] =
              h.cachedItems["articulo_id"][line["articulo_id"]]["familia_id"];
          }
          if (id in datos) {
            (datos[id].unidades += floatOrZero(line["unidades"])),
              (datos[id].precio +=
                floatOrZero(line["precio"]) * floatOrZero(line["unidades"]));
          } else {
            datos[id] = {
              unidades: floatOrZero(line["unidades"]),
              precio:
                floatOrZero(line["precio"]) * floatOrZero(line["unidades"]),
            };
          }
        }
      });
      for (let fid in datos) {
        let d = datos[fid];
        darr.push({
          familia_id: fid,
          unidades: d.unidades,
          precio_medio: (d.precio / d.unidades).toFixed(2),
        });
      }
      self.$set(self.formData, "estadisticas_familias", darr);
    },
    onActiveTab(i) {
      let self = this;
      switch (i) {
        case 0: //general
          self.actualizar_estadisticas_familias();
          break;
      }
    },
  },
  mounted() {
    var self = this;

    self.$refs["main-tabs"].$refs.toolbar.addEventListener(
      "dblclick",
      function (e) {
        if (self.app.session.mode == "a") return;
        if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
          if (
            self.mode != "edit" &&
            self.mode != "new" &&
            self.mode != "search"
          )
            return;
          self.$set(self.formData, "wt", !parseInt(self.formData.wt) ? 1 : 0);
          self.$emit("change", "wt", "edit");
          if (self.mode == "search") self.setFilter(self.getFilter());
        }
      }
    );

    self.$on("loadItem", function () {
      self.actualizar_estadisticas_familias();
    });

    //Al cambiar líneas
    self.$refs.lineas.field.hotInstance.addHook(
      "beforeChange",
      function (changes, source) {
        if (source == "loadData") return;
        if (source == "loadData" || source == "load") return;
        if (!changes) return;
        var h = this;
        changes.forEach(function (change, index) {
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];

          if (
            prop != "ean13" &&
            prop != "articulo_id" &&
            !h.getSourceData()[row].articulo_id
          ) {
            changes[index] = null;
            return;
          }
          if (
            ["totallinea", "precio", "peso", "unidades"].includes(prop) &&
            newVal == null
          ) {
            changes[index][3] = 0;
          }

          if (prop == "etiqueta") {
            if (newVal < 0) {
              changes[index][3] = "0";
            } else if (floatOrZero(newVal) % 1 !== 0) {
              changes[index][3] = Math.round(newVal);
            }
          }

          if (prop == "t" && newVal) {
            if (
              !Array.from(Array(10), (_, i) => i + 1).includes(
                parseInt(newVal || 0)
              )
            ) {
              changes[index][3] = null;
              self.app.toast("Tarifa incorrecta (1-10)", "error");
            }
          }

          if (prop == "v" && newVal) {
            let newVal =
              typeof change[3] == "string" ? change[3].toUpperCase() : null;
            if (!["U", "P"].includes(newVal)) {
              changes[index][3] = null;
              self.app.toast("Valor incorrecto (U/P)", "error");
            } else {
              changes[index][3] = newVal;
            }
          }
        });
      }
    );

    self.$refs.lineas.field.hotInstance.addHook(
      "afterChange",
      function (changes, source) {
        if (source == "loadData") return;
        if (!changes) return;
        var h = this;
        changes.forEach(function (change, index) {
          if (!change) return;
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];
          var val = (columna) => h.getDataAtRowProp(row, columna) || 0;
          if (
            ((prop == "ean13" || prop == "articulo_id") &&
              newVal &&
              oldVal != newVal &&
              source != "auto") ||
            source == "newproduct"
          ) {
            var p = window.DB.getList("articulo", {
              limit: 1,
              filter: [prop == "articulo_id" ? "codigo" : "ean13", "=", newVal],
              depth: 3,
              fields: [
                "codigo",
                "ean13",
                { name: "modelo_id", fields: ["codigo", "nombre"] },
                { name: "familia_id", fields: ["codigo", "nombre"] },
                { name: "tipo_comision_id", fields: ["codigo", "descripcion"] },
                "nombre",
                "unidad_medida_compra",
                "compra_metal_incluido",
                "recargo_equivalencia",
                "incluir_contraste_pcoste",
                "incluir_ocostes_pcoste",
                "precio_coste",
                "unidad_medida_venta",
                "venta_metal_incluido",
                "peso_unitario_fino",
                "margen_ultima_compra",
                {
                  name: "taqueria_ids",
                  fields: [
                    "codigo",
                    "nombre",
                    { name: "thumbnail", fields: ["imagen"] },
                    "precio_coste",
                  ],
                },
                "taqueria_precio",
                { name: "thumbnail", fields: ["imagen"] },
                "tarifa1",
                "tarifa2",
                "tarifa3",
                "tarifa4",
                "tarifa5",
                "tarifa6",
                "tarifa7",
                "tarifa8",
                "tarifa9",
                "tarifa10",
              ],
            })
              .then(function (res) {
                if (parseInt(res.count)) {
                  let a = res.data[0];
                  //self.cachedArticulosCodigo[a.codigo] = a;
                  //self.cachedArticulosEan13[a.ean13] = a;

                  let d = h.getSourceData()[row];
                  d.articulo_id = a;
                  d.ean13 = a.ean13;
                  d.modelo_id = a.modelo_id;
                  //d.familia_id = a.familia_id.codigo;
                  d.descripcion = a.nombre;
                  d.unidades = 1;
                  d.etiqueta = 1;
                  d.peso = floatOrZero(a.peso_unitario_fino);
                  d.v = a.unidad_medida_venta == "peso" ? "P" : "U";
                  d.t = 1;
                  let divisa = 1;
                  d.precio = floatOrZero(a["tarifa" + 1] * divisa).toFixed(2);
                  self.actualizar_totallinea(row);

                  h.render();
                  //TODO? trigger afterChange rHandsontable?
                  self.actualizar_total_unidades();
                  self.actualizar_total_peso();
                } else {
                  self.app.toast("No se han encontrado el artículo", "error");
                  h.setDataAtRowProp(row, prop, oldVal, "auto");
                  //h.selectCell(row, prop);
                }
              })
              .catch(function (res) {
                window.console.log(res);
              });
          }
          if (["unidades"].includes(prop) && oldVal != newVal) {
            if (oldVal) {
              var peso = (newVal * val("peso")) / oldVal;
            } else {
              peso = 0;
            }
            h.getSourceData()[row].peso = peso;
            self.actualizar_total_unidades();
            self.actualizar_total_peso();
          }
          if (prop == "peso" && oldVal != newVal) {
            self.actualizar_total_peso();
          }
          if (
            ["precio", "v", "peso", "unidades"].includes(prop) &&
            oldVal != newVal
          ) {
            self.actualizar_totallinea(row);
          }
          //Actualizar precio según tarifa
          if (prop == 't' && oldVal != newVal) {
              let articulo_id = h.getDataAtRowProp(row, 'articulo_id');
              if (articulo_id && h.cachedItems['articulo_id'] && h.cachedItems['articulo_id'][articulo_id]) {
                  let tarifa = floatOrZero(h.cachedItems['articulo_id'][articulo_id]['tarifa' + newVal]);
                  if (!isFinite(tarifa)) tarifa = 0;
                  //h.setDataAtRowProp(row, 'precio', tarifa, 'auto');
                  h.getSourceData()[row].precio = tarifa;
                  setTimeout(function () { self.actualizar_totallinea(row); }, 0);
              }
          }
        });
      }
    );

    self.$refs.lineas.field.hotInstance.updateSettings({
      enterMoves: function (event) {
        let h = self.$refs.lineas.field.hotInstance;
        let sel = h.getSelectedLast();
        let prop = h.colToProp(sel[1]);
        let value = h.getSourceData()[sel[0]][prop];
        if ((prop == "ean13" || prop == "articulo_id") && value) {
          return { row: 1, col: 0 };
        }
        return { row: 0, col: 1 };
      },
    });

    self.$on("loadItem", function () {
      self.actualizar_total_unidades();
      self.actualizar_total_peso();
    });

    self.$refs.lineas.field.$on("removeRow", function () {
      self.actualizar_total_unidades();
      self.actualizar_total_peso();
    });

    self.renderH = self.$utils.misc.debounce(function (h) {
      h.render();
    }, 200);
  },
};
</script>